
import TextField from "@guestyci/foundation/TextField";
import { Row } from "@guestyci/foundation/Layout";
import t from "@guestyci/localize/t.macro";
import Divider from "@guestyci/foundation/Divider";

import HouseRules from "components/HouseRules";

const HouseRulesSection = ({ property = {} } = {}) => {
  const { _id, unitTypeHouseRules: { houseRules = null } = {} } = property || {};
  if (!houseRules) return null;

  return (
    <div>
      <Divider className="mt-6 mb-6" />
      <TextField bold className="mb-2">
        {t('House rules')}
      </TextField>
      <Row>
        <HouseRules unitTypeHouseRules={property.unitTypeHouseRules} listingId={_id} />
      </Row>
    </div>
  );
}

export default HouseRulesSection;