import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import cn from 'classnames';

import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Input from '@guestyci/foundation/Input';
import Accordion from '@guestyci/foundation/Accordion';
import DropdownMenu from '@guestyci/foundation-legacy/baseDropdown/DropdownMenu';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { getSymbolFromCurrency, prettifyEnumText } from 'utils';
import useSearchValues from 'hooks/useSearchValues';
import useIsMobile from 'hooks/useIsMobile';
import currencySymbolMap from 'constants/currencies';
import { CurrencyContext } from 'context/CurrencyContext';
import Icon from 'components/Icon';
import { PRICE_FILTER_FIX } from 'constants/featureToggleNames';
import { getItemTranslation, useConfigSettings } from 'hooks/useConfigSettings';
import useTotalPrice from 'hooks/useTotalPrice';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [create('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: 0,
    },
    [create('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    cursor: 'pointer',
  },
  priceInput: {
    display: 'flex',
  },
  dropdownRoot: {
    width: 'initial',
    marginRight: 23,
    cursor: 'pointer',
    [create('xs')]: {
      padding: 15,
      width: '100%',
    },
    [create('md')]: {
      width: 'initial',
      maxWidth: 300,
    },
    [create('lg')]: {
      padding: 0,
    },
  },
  block: {
    display: 'flex',
    [create('xs')]: {
      borderLeft: 'none',
      padding: 0,
    },
    [create('lg')]: {
      borderLeft: '1px solid #77838D',
      padding: '0 15px',
    },
  },
  total: {
    [create('xs')]: {
      margin: 15,
    },
    [create('lg')]: {
      margin: 0,
    },
  },
  accordion: {
    paddingBottom: 0,
    '& button': {
      padding: '0 15px',
    },
  },
  clearAll: {
    cursor: 'pointer',
    [create('xs')]: {
      margin: 15,
    },
    [create('lg')]: {
      margin: 0,
    },
  },
  dropdownOption: {
    width: 300,
  },
}));

const SearchFilters = ({ totalListings, params }) => {
  const {
    allPropertyTypes: propertyTypes,
    allAmenityTypes: amenityTypes,
    newPropertyTypes,
    newAmenityTypes,
  } = useConfigSettings();

  const {
    contentConfiguration: { searchSettings } = {},
    contentConfiguration: { searchSettings: { filterByTags } = {} } = {},
  } = useContext(WebsiteSettingsContext);

  const categoriesFilterName = useMemo(() => {
    return filterByTags?.customName ? `${filterByTags.customName}:` : t('Categories:');
  }, [filterByTags]);

  const { isTotalPriceEnabled } = useTotalPrice();

  const {
    enableBathroomsFilter: { value: maxBathrooms = 10, active: activeBathroom = true } = {},
    enableBedroomsFilter: { value: maxBedrooms = 10, active: activeBedroom = true } = {},
    filterByTags: { value: tagsData, translations: tagsTranslations, active: activeTags = true } = {},
    filterByAmenities: { value: amenitiesData, active: activeAmenities = true } = {},
    filterByPropertyType: { value: propertyData, active: activePropertyType = true } = {},
  } = searchSettings || {};

  const tagsTranslationsMapper = useMemo(() => {
    return tagsTranslations?.reduce((acc, el) => {
      acc[el.id] = el.label;
      return acc;
    }, {});
  }, [tagsTranslations]);

  const amenitiesList = amenitiesData?.length
    ? amenitiesData.reduce((acc, el) => {
        // eslint-disable-next-line no-prototype-builtins
        if (amenityTypes.hasOwnProperty(el)) {
          acc[el] = amenityTypes[el];
        }
        return acc;
      }, {})
    : newAmenityTypes;

  const propertiesList = propertyData?.length
    ? propertyData.reduce((acc, el) => {
        // eslint-disable-next-line no-prototype-builtins
        if (propertyTypes.hasOwnProperty(el)) {
          acc[el] = propertyTypes[el];
        }
        return acc;
      }, {})
    : newPropertyTypes;

  const isMobile = useIsMobile();
  const { root, accordion, priceWrapper, priceInput, dropdownRoot, block, total, clearAll, dropdownOption } =
    useStyles();
  const { defaultCurrency, selectedCurrency } = useContext(CurrencyContext);
  const currencySymbol = getSymbolFromCurrency(selectedCurrency, currencySymbolMap);
  const searchParams = useSearchValues();
  const [open, setOpen] = useState(false);
  const [isRangeCollapsed, setIsRangeCollapsed] = useState(true);
  const [minPrice, setMinPrice] = useState(searchParams.minPrice || null);
  const [maxPrice, setMaxPrice] = useState(searchParams.maxPrice || null);
  const [propertyType, setPropertyType] = useState(searchParams.getParsedPropertyTypes());
  const [tags, setTags] = useState(searchParams.getParsedTags());
  const [amenities, setAmenities] = useState(searchParams.getParsedAmenities());
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(searchParams.numberOfBedrooms || 0);
  const [numberOfBathrooms, setNumberOfBathrooms] = useState(searchParams.numberOfBathrooms || 0);
  const [, isPriceFilterFixEnabled] = useFeatureToggle(PRICE_FILTER_FIX);

  const showPropertiesCount = !isPriceFilterFixEnabled || !(minPrice || maxPrice);
  const translatedPropertyType = getItemTranslation({
    allTranslations: propertyTypes,
    selectedValue: prettifyEnumText(propertyType),
  });

  const minPriceText = minPrice ? currencySymbol + minPrice : '0';
  const maxPriceText = maxPrice ? currencySymbol + maxPrice : 'max';
  const allText = t('All');
  const propertyTypeText = `${t('Property Type:')} ${propertyType ? translatedPropertyType : allText}`;
  const tagsText = `${categoriesFilterName} ${
    tags.length ? prettifyEnumText(tagsTranslationsMapper?.[tags[0]] || tags[0]) : allText
  }`;
  const firstTranslatedAmenity = getItemTranslation({
    allTranslations: amenityTypes,
    selectedValue: prettifyEnumText(prettifyEnumText(amenities[0])),
  });
  const amenitiesText = `${t('Amenities:')} ${amenities.length ? firstTranslatedAmenity : allText}`;
  const numberOfBedroomsText = `${t('Number of bedrooms:')} ${numberOfBedrooms || allText}`;
  const numberOfBathroomsText = `${t('Number of bathrooms:')} ${numberOfBathrooms || allText}`;

  const handleChangePropertyType = useCallback((event) => {
    const { value } = event.target;
    setPropertyType(value);
  }, []);

  const handleChangeTags = useCallback((event) => {
    const { value } = event.target;
    setTags(value);
  }, []);

  const handleChangeAmenities = useCallback((event) => {
    const { value } = event.target;
    setAmenities(value);
  }, []);

  const handleChangeNumberOfBedrooms = useCallback((event) => {
    const { value } = event.target;
    setNumberOfBedrooms(value);
  }, []);

  const handleChangeNumberOfBathrooms = useCallback((event) => {
    const { value } = event.target;
    setNumberOfBathrooms(value);
  }, []);

  const handleChangeMinPrice = useCallback((event) => {
    const { value } = event.target;
    setMinPrice(parseInt(value, 10));
  }, []);

  const handleChangeMaxPrice = useCallback((event) => {
    const { value } = event.target;
    setMaxPrice(parseInt(value, 10));
  }, []);

  const resetPriceHandler = useCallback(() => {
    setMinPrice(null);
    setMaxPrice(null);
  }, []);

  const resetPrice = useCallback(
    (e) => {
      e.stopPropagation();
      resetPriceHandler();
    },
    [resetPriceHandler]
  );

  const resetPropertyType = useCallback((e) => {
    e.stopPropagation();
    setPropertyType(null);
  }, []);

  const resetTags = useCallback((e) => {
    e.stopPropagation();
    setTags([]);
  }, []);

  const resetAmenities = useCallback((e) => {
    e.stopPropagation();
    setAmenities([]);
  }, []);

  const resetNumberOfBedrooms = useCallback((e) => {
    e.stopPropagation();
    setNumberOfBedrooms(0);
  }, []);

  const resetNumberOfBathrooms = useCallback((e) => {
    e.stopPropagation();
    setNumberOfBathrooms(0);
  }, []);

  const clearAllHandler = useCallback(() => {
    setTags([]);
    setAmenities([]);
    setMaxPrice(null);
    setMinPrice(null);
    setNumberOfBathrooms(0);
    setNumberOfBedrooms(0);
    setPropertyType(null);
  }, []);

  const handleClosePriceFilter = useCallback(() => {
    if (minPrice && maxPrice && minPrice > maxPrice) {
      return;
    }
    setIsRangeCollapsed(true);
  }, [minPrice, maxPrice]);

  useEffect(() => {
    params({
      currency: defaultCurrency,
      tags: tags.toString(),
      includeAmenities: amenities.toString(),
      maxPrice,
      minPrice,
      numberOfBathrooms,
      numberOfBedrooms,
      propertyType,
    });
  }, [minPrice, maxPrice, propertyType, tags, amenities, numberOfBedrooms, params, defaultCurrency, numberOfBathrooms]);

  const content = (
    <div className={root}>
      <div className={priceWrapper}>
        <div className="d-flex align-items-center">
          <TextField className={cn(block, 'border-0')} color="input" bold onClick={() => setIsRangeCollapsed(false)}>
            <span>
              {t('Price per night:')}
              &nbsp;
            </span>
            {!minPrice && !maxPrice ? <span>{t('All')}</span> : <span>{`${minPriceText}-${maxPriceText}`}</span>}
          </TextField>
          {maxPrice || minPrice ? <Icon icon="close" size={12} onClick={resetPrice} /> : null}
        </div>

        <DropdownMenu isCollapsed={isRangeCollapsed} onDismiss={handleClosePriceFilter}>
          <div className={priceInput}>
            <Input
              value={minPrice}
              onChange={handleChangeMinPrice}
              type="number"
              placeholder="0"
              min={0}
              max={maxPrice}
              prefix={currencySymbol}
              error={maxPrice && minPrice > maxPrice ? t('Wrong min value') : ''}
            />
            <div className="p-2">-</div>
            <Input
              value={maxPrice}
              onChange={handleChangeMaxPrice}
              type="number"
              placeholder={t('Max')}
              min={minPrice}
              prefix={currencySymbol}
              error={maxPrice && maxPrice < minPrice ? t('Wrong max value') : ''}
            />
          </div>
        </DropdownMenu>
      </div>
      {!!(activeTags && tagsData?.length) && (
        <Dropdown
          value={tags}
          className={dropdownRoot}
          variant="multiselect"
          onChange={handleChangeTags}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {tagsText}
                {tags.length > 1 && ` +${tags.length - 1}`}
              </TextField>
              {tags.length ? <Icon icon="close" size={12} onClick={resetTags} /> : null}
            </div>
          }
        >
          {tagsData.map((tag) => (
            <Option value={tag}>{tagsTranslationsMapper?.[tag] || tag}</Option>
          ))}
        </Dropdown>
      )}
      {activePropertyType && (
        <Dropdown
          value={propertyType}
          className={dropdownRoot}
          onChange={handleChangePropertyType}
          variant="single"
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {propertyTypeText}
              </TextField>
              {propertyType && <Icon icon="close" size={12} onClick={resetPropertyType} />}
            </div>
          }
        >
          <Option value={null}>{t('All')}</Option>
          {Object.entries(propertiesList).map(([, { value, label }]) => {
            return <Option value={value}>{label}</Option>;
          })}
        </Dropdown>
      )}
      {activeAmenities && (
        <Dropdown
          value={amenities}
          className={dropdownRoot}
          variant="multiselect"
          onChange={handleChangeAmenities}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {amenitiesText}
                {amenities.length > 1 && ` +${amenities.length - 1}`}
              </TextField>
              {amenities.length ? <Icon icon="close" size={12} onClick={resetAmenities} /> : null}
            </div>
          }
        >
          {Object.entries(amenitiesList).map(([, { value, label }]) => {
            return (
              <Option className={dropdownOption} value={value}>
                {label}
              </Option>
            );
          })}
        </Dropdown>
      )}
      {activeBedroom && (
        <Dropdown
          value={numberOfBedrooms}
          className={dropdownRoot}
          variant="single"
          withSelectAll
          onChange={handleChangeNumberOfBedrooms}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {numberOfBedroomsText}
              </TextField>
              {numberOfBedrooms ? <Icon icon="close" size={12} onClick={resetNumberOfBedrooms} /> : null}
            </div>
          }
        >
          <Option value={0}>{t('All')}</Option>
          {Array.from({ length: maxBedrooms }, (_, i) => i + 1).map((e) => (
            <Option value={e}>{e}</Option>
          ))}
        </Dropdown>
      )}
      {activeBathroom && (
        <Dropdown
          value={numberOfBathrooms}
          className={dropdownRoot}
          variant="single"
          withSelectAll
          onChange={handleChangeNumberOfBathrooms}
          input={
            <div className="d-flex align-items-center">
              <TextField className={block} color="input" bold>
                {numberOfBathroomsText}
              </TextField>
              {numberOfBathrooms ? <Icon icon="close" size={12} onClick={resetNumberOfBathrooms} /> : null}
            </div>
          }
        >
          <Option value={0}>{t('All')}</Option>
          {Array.from({ length: maxBathrooms }, (_, i) => i + 1).map((e) => (
            <Option value={e}>{e}</Option>
          ))}
        </Dropdown>
      )}

      {showPropertiesCount && (
        <TextField className={cn(block, total)} variant="h7" color="secondary">
          {t(
            '{ num, plural, =0 {# Properties} =1 {# Property} few {# Properties} many {# Properties} other {# Properties}}',
            {
              num: totalListings || 0,
            }
          )}
          {isTotalPriceEnabled && ` ${t('found')}`}
        </TextField>
      )}

      <TextField className={cn(block, clearAll)} variant="h7" color="link" onClick={clearAllHandler}>
        {t('Clear all')}
      </TextField>
    </div>
  );

  return isMobile ? (
    <Accordion title={t('Filters')} className={accordion} open={open} onChange={() => setOpen(!open)}>
      {content}
    </Accordion>
  ) : (
    content
  );
};

export default SearchFilters;
