import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

import createInstantReservation from 'api/quote/createInstantReservation';
import createInstantGroupReservation from 'api/quote/createInstantGroupReservation';
import createInstantChargeReservation from 'api/quote/createInstantChargeReservation';
import {
  BANK_TRANSFER_PAYMENT_TYPE,
  CREDIT_CARD_PAYMENT_TYPE,
  GUEST_FORM_VALUES_LOCALSTORAGE_KEY,
} from 'constants/constants';
import { createSingleReservationParams, createGroupReservationParams } from 'utils';
import { useZeroAmountNotificationDialog } from 'components/ZeroAmountNotificationDialog';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useHandleStripe from './useHandleStripe';
import useTrackCreateReservationSuccess from './useTrackCreateReservationSuccess';
import useTrackCreateReservationError from './useTrackCreateReservationError';
import useGetPathToNavigate from './useGetPathToNavigate';
import useIsGroupReservation from './useIsGroupReservation';
import useSearchValues from './useSearchValues';
import useLocalStorage from './useLocalStorage';
import { ERROR_TYPES } from '../components/ErrorDialog/PaymentErrorDialog';

const useCreateInstant = () => {
  return useMutation(createInstantReservation);
};

const useCreateInstantGroup = () => {
  return useMutation(createInstantGroupReservation);
};

const useCreateInstantCharge = () => {
  return useMutation(createInstantChargeReservation);
};

const useSubmitInstantStripe = ({ property, reservationTotalAmount }) => {
  const { getPathWithLocale, locale } = useGetPathToNavigate();
  const history = useHistory();
  const { rooms } = useSearchValues();
  const { state } = useLocation();
  const [, setGuestFormValues, removeGuestFormValues] = useLocalStorage(GUEST_FORM_VALUES_LOCALSTORAGE_KEY, {});
  const { isGroupReservation, isGroupReservationEnabled } = useIsGroupReservation();
  const { mutateAsync: handleCreateInstantReservation, isLoading: isCreatingInstant } = useCreateInstant();
  const { mutateAsync: handleCreateInstantGroupReservation, isLoading: isCreatingInstantGroupReservation } =
    useCreateInstantGroup();
  const { mutateAsync: handleCreateInstantChargeReservation, isLoading: isCreatingInstantChargeReservation } =
    useCreateInstantCharge();
  const { trackSuccess } = useTrackCreateReservationSuccess({
    property,
    provider: 'stripe',
    type: 'instant',
    reservationTotalAmount,
  });
  const { trackError } = useTrackCreateReservationError({ property, provider: 'stripe', type: 'instant' });
  const {
    zeroAmountNotificationDialogIsOpen,
    openZeroAmountNotificationDialog,
    isFeatureEnabled: isZeroAmountNotificationEnabled,
  } = useZeroAmountNotificationDialog();
  const {
    displayOptions: { instantCharge },
  } = useContext(WebsiteSettingsContext);

  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [reservationError, setReservationError] = useState(false);

  const successPath = getPathWithLocale('/instant-success');

  const { confirmCardSetup, createConfirmationToken, handleNextAction } = useHandleStripe();

  const isRequestingReservation =
    isCreatingInstant || isCreatingInstantGroupReservation || isCreatingInstantChargeReservation;

  const submitInstantSingle = async (values) => {
    const { paymentType = CREDIT_CARD_PAYMENT_TYPE, reusePaymentMethod } = values;
    try {
      let selectedRatePlanId;
      let selectedQuoteId;
      if (isGroupReservationEnabled) {
        selectedRatePlanId = state?.ratePlan?._id;
        selectedQuoteId = state?.quoteData?.quote[0]?._id;
      } else {
        selectedRatePlanId = state?.ratePlanId;
        selectedQuoteId = state?.quoteId;
      }
      const params = createSingleReservationParams(values, selectedRatePlanId, locale);
      if (paymentType === BANK_TRANSFER_PAYMENT_TYPE) {
        const response = await handleCreateInstantReservation({
          quoteId: selectedQuoteId,
          params: {
            ...params,
            paymentType,
          },
        });

        trackSuccess({
          response,
          dioAdditionalData: { paymentType },
        });
        history.push({
          pathname: successPath,
          state: {
            paymentType,
          },
        });
        return;
      }

      if (instantCharge) {
        setIsStripeLoading(true);

        const stripeResponse = await createConfirmationToken({ values });

        setIsStripeLoading(false);

        if (stripeResponse.error) {
          setStripeError({
            type: ERROR_TYPES.PROCESSOR_ERROR,
            message: stripeResponse.error.message,
          });
          return;
        }

        const { reservation, payment } = await handleCreateInstantChargeReservation({
          quoteId: selectedQuoteId,
          params: {
            ...params,
            reuse: reusePaymentMethod,
            confirmationToken: stripeResponse.confirmationToken.id,
          },
        });

        if (payment.status === 'PENDING_AUTH') {
          setIsStripeLoading(true);

          const { error } = await handleNextAction({
            clientSecret: payment.attempts[0].payload.payment_intent.client_secret,
          });

          setIsStripeLoading(false);

          if (error) {
            setGuestFormValues(values);
            setStripeError({
              type: ERROR_TYPES.CHARGE_ERROR,
              message: error.message,
            });
            return;
          }
        } else if (payment.status !== 'SUCCEEDED') {
          setGuestFormValues(values);
          setStripeError({
            type: ERROR_TYPES.CHARGE_ERROR,
            message: payment.attempts?.[0]?.payload?.message,
          });
          return;
        }

        trackSuccess({
          response: reservation,
          dioAdditionalData: { paymentType, instantCharge },
        });

        removeGuestFormValues();
        history.push({
          pathname: successPath,
          state: {
            paymentType,
            instantCharge,
          },
        });
        return;
      }

      setIsStripeLoading(true);
      if (isZeroAmountNotificationEnabled) {
        await openZeroAmountNotificationDialog();
      }
      const stripeResponse = await confirmCardSetup({ values });
      setIsStripeLoading(false);
      if (stripeResponse.error) {
        setStripeError({
          type: ERROR_TYPES.PROCESSOR_ERROR,
          message: stripeResponse.error.message,
        });
        return;
      }
      const reservation = await handleCreateInstantReservation({
        quoteId: selectedQuoteId,
        params: {
          ...params,
          reuse: reusePaymentMethod,
          ccToken: stripeResponse.setupIntent.payment_method,
        },
      });
      trackSuccess({
        response: reservation,
        dioAdditionalData: { paymentType },
      });
      history.push({
        pathname: successPath,
        state: {
          paymentType,
        },
      });
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
        additionalData: { paymentType },
      });
    }
  };

  const submitInstantGroup = async (values) => {
    const { paymentType = CREDIT_CARD_PAYMENT_TYPE, reusePaymentMethod } = values;
    try {
      const ratePlanId = state?.ratePlan?._id;
      const quoteData = state?.quoteData;

      const params = createGroupReservationParams({ values, rooms, quoteData, ratePlanId, locale });

      if (paymentType === BANK_TRANSFER_PAYMENT_TYPE) {
        const response = await handleCreateInstantGroupReservation({
          params: {
            ...params,
            paymentType,
          },
        });

        trackSuccess({
          response,
          dioAdditionalData: { paymentType },
        });
        history.push({
          pathname: successPath,
          state: {
            paymentType,
          },
        });
        return;
      }

      setIsStripeLoading(true);
      if (isZeroAmountNotificationEnabled) {
        await openZeroAmountNotificationDialog();
      }
      const stripeResponse = await confirmCardSetup({ values });
      setIsStripeLoading(false);
      if (stripeResponse.error) {
        setStripeError({
          type: ERROR_TYPES.PROCESSOR_ERROR,
          message: stripeResponse.error.message,
        });
        return;
      }
      const reservation = await handleCreateInstantGroupReservation({
        params: {
          ...params,
          reuse: reusePaymentMethod,
          ccToken: stripeResponse.setupIntent.payment_method,
        },
      });
      trackSuccess({
        response: reservation,
        dioAdditionalData: { paymentType },
      });
      history.push({
        pathname: successPath,
        state: {
          paymentType,
        },
      });
    } catch (error) {
      setReservationError(error?.response?.data?.error?.message || true);
      trackError({
        error,
        additionalData: { paymentType },
      });
    }
  };

  const submitHandler = isGroupReservation ? submitInstantGroup : submitInstantSingle;
  return {
    isRequestingReservation,
    isStripeLoading,
    stripeError,
    setStripeError,
    submitHandler,
    setReservationError,
    reservationError,
    zeroAmountNotificationDialogIsOpen,
  };
};

export default useSubmitInstantStripe;
